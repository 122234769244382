import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

class Slideshow extends React.Component {
    render() {
        var settings = {
            arrows: true,
            autoplay: true,
            autoplaySpeed: 10000,
            speed: 800,
            dots: false,
            fade: true,
            pauseOnHover: false,  
        };
        return (
            <Slider className="slideshow" {...settings}>
                {this.props.allSlideshowJson.edges.map((edge) => {
                    return (
                        <div className="slideshow-item" key={edge.node.internal.contentDigest}>
                            <Img fluid={edge.node.image.childImageSharp.fluid} className="slideshow-img" alt={edge.node.title } />
                            <div className="slideshow-label">
                                <div className="slideshow-title" dangerouslySetInnerHTML={{ __html: edge.node.title }} />
                                <p dangerouslySetInnerHTML={{ __html: edge.node.caption }} />
                            </div>
                        </div>
                    )
                })}
            </Slider>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                allSlideshowJson {
                    edges {
                        node {
                            caption
                            image {
                                childImageSharp {
                                    fluid(maxWidth:2000) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            title
                            internal {
                                contentDigest
                            }
                        }
                    }
                }
            }
        `}
        render={
            (data) => (
                <Slideshow allSlideshowJson={data.allSlideshowJson} />
            )
        }
    />
)
