import React from "react";
import SEO from "components/seo";
import Layout from "components/layout";
import Slideshow from "components/slideshow";

function IndexPage() {
    return (
        <Layout>
            <SEO
                pageData={{
                    title: `Sydney Football Stadium`,
                    description: `Home page description.`
                }}
            />
            <Slideshow />
        </Layout>
    );
}

export default IndexPage;
